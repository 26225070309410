<template>
   <v-form ref="redeem-item-form" @submit.prevent="formSubmit">
      <v-row>
         <v-col cols="12">
            <h3>Redeem item detail</h3>
         </v-col>
         <v-col cols="12">
            <v-btn color="secondary" class="m-auto px-8" large @click.prevent="$router.go(-1)" :disabled="isFetching">Back</v-btn>
         </v-col>
         <v-col cols="12">
            <v-card>
               <v-card-text>
                  <v-row>
                     <v-col md="6" cols="12">
                        <ImageBtnUpload v-model="data.cover_image" label="Cover image" :required="true" />
                     </v-col>
                     <v-col md="6" cols="12"></v-col>
                     <v-col md="6" cols="12">
                        <FormControl inputType="string" v-model="data.title" label="Item title" :required="true" />
                     </v-col>
                     <v-col md="6" cols="12">
                        <FormControl inputType="number" v-model="data.credit" label="Credit amount" :required="true" />
                     </v-col>
                     <v-col md="12" cols="12">
                        <FormControl inputType="textarea" v-model="data.description" label="description" :rows="8" :required="true" />
                     </v-col>
                  </v-row>
               </v-card-text>
            </v-card>
         </v-col>
         
         <v-col cols="12">
            <v-btn color="primary" class="px-8 form-btn" large :disabled="isFetching" type="submit">Save</v-btn>
            <FormDeleteBtn :removeAction="removeItem" v-if="!isNew" />
         </v-col>
      </v-row>
   </v-form>
</template>

<script>
import FormButtonUpload from '@/components/form/FormButtonUpload'
import FormControl from '@/components/form/FormControl'
import FormDeleteBtn from '@/components/form/FormDeleteBtn'
import ImageBtnUpload from '@/components/form/ImageBtnUpload'
import { mapActions, mapState } from 'vuex'
import masterData from '@/components/master-data'

export default {
   name: 'RedeemItemDetail',
   components: {
      FormControl,
      FormButtonUpload,
      FormDeleteBtn,
      ImageBtnUpload,
   },
   data() {
      return {
         isNew: true,
         data: {
            id: _.toNumber(this.$route.params.id),
            title: '',
            category_id: '',
            credit: 0,
            cover_image: '',
            description: '',
         },
         dataOptions: {
            itemStatus: masterData.item_status,
         }
      }
   },
   computed: {
      ...mapState({
         isFetching: (state) => state.request.isFetching,
         isSuccess: (state) => state.request.isSuccess,
         isFail: (state) => state.request.isFail,
      }),
   },
   methods: {
      ...mapActions(['sendRequest'], 'request'),
      ...mapActions(['setDialogMessage', 'setShowDialog']),
      
      async initData() {
         const resp = await this.sendRequest({ type: 'get_redeem_item_by_id', data: { id: this.data.id } })
         if (!resp) return
         this.isNew = false
         this.data = {
            ...this.data,
            title: resp.data.title,
            credit: resp.data.credit,
            cover_image: resp.data.cover_image,
            description: resp.data.description,
         }
      },
      async formSubmit() {
         console.log('save')
         const isValid = this.$refs['redeem-item-form'].validate()
         if (isValid) {
            const action = this.isNew ? this.createItem : this.updateItem
            const resp = await action()
            this.setDialogMessage({ 
               message: resp ? 'Successful' : 'Failed', 
               isError: !resp, 
               returnLink: (this.data.id === undefined ? { name: 'redeemItem' } : null )
            })
            this.setShowDialog(true)
            if (!!resp && this.isNew) {
               this.isNew = false
               this.data.id = resp.data.id
               this.initData()
               this.$router.replace({ name: 'redeemItemDetail', params: { id: resp.data.id } })
            }
         }
      },
      async createItem() {
         console.log('create')
         const formdata = {
            data_status: 'active',
            status: this.data.status,
            title: this.data.title,
            cover_image: this.data.cover_image,
            description: this.data.description,
            credit: _.toNumber(this.data.credit),
         }
         return await this.sendRequest({ type: 'add_redeem_item', data: formdata })
      },
      async updateItem() {
         console.log('update')
         const formdata = {
            data_type: 'redeem_item_data',
            id: this.data.id,
            status: this.data.status,
            title: this.data.title,
            cover_image: this.data.cover_image,
            description: this.data.description,
            credit: _.toNumber(this.data.credit),
         }
         return await this.sendRequest({ type: 'update_redeem_item', data: formdata })
      },
      async removeItem(dialog) {
         console.log('delete')
         dialog.value = false
         const formdata = {
            data_type: 'redeem_item_data',
            id: this.data.id,
            status: 'inactive',
         }
         const resp = await this.sendRequest({ type: 'update_redeem_item', data: formdata })
         if (!resp) {
            this.setDialogMessage({ 
               message: 'Failed', 
               isError: true,
            })
            this.setShowDialog(true)
            return
         }
         this.$router.push({name: 'redeemItem'})
      },
   },
   async mounted() {
      await this.initData()
   }
}
</script>
